.rbc-time-column .rbc-time-slot > span {
  font-size: 0.8rem;
  top: -0.9rem;
  width: 4rem;
  display: inline-block;
  left: 0;
  text-align: right;
}

.rbc-day-slot .rbc-event {
  padding: 0;
  border: none;
  background-color: transparent;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-time-column .rbc-timeslot-group {
  min-height: 80px;
}
