body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Montserrat';
}

*:focus {
  outline: none;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

@media (min-width: 640px) {
  html {
    font-size: 16px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e5e5e5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.blue-green-gradient {
  background: linear-gradient(
    90deg,
    #6ab8c5 0%,
    #3a59bf 99.99%,
    #3958bf 100%
  ) !important;
}

.dark-blue-green-gradient {
  background: linear-gradient(90deg, #3958bf 17.06%, #6ab8c5 99.99%);
}

.blue-purple-gradient-text {
  background: linear-gradient(90deg, #4b42c6 0%, #3b82d4 100%);
  background-clip: text;
  color: transparent;
  font-weight: 500;
}

.white {
  background: #fff;
}

.overlay-linear-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

/* charka-ui custom style */
.chakra-switch__track {
  width: 2.75rem !important;
  background-color: #e5e5e5 !important;
}
.chakra-switch__track[data-checked] {
  background-color: #e1f2fe !important;
}
.chakra-switch__thumb {
  background-color: #828282 !important;
}
.chakra-switch__thumb[data-checked] {
  background-color: #3959bf !important;
  transform: translateX(1.75rem) !important;
}
.chakra-checkbox__control[data-checked] {
  background: #3959bf !important;
  border-color: #3959bf !important;
}
button:disabled {
  background: #c5c5c5 !important;
  opacity: 0.4;
}

.table__user-selectionInput {
  transform: scale(1.25);
  margin-top: 0.25rem;
}

/* Date-time-picker custom style */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  font-size: 0.875rem;
}

.table__user-selectionInput {
  transform: scale(1.25);
  margin-top: 0.25rem;
}

/* styling for session status */
.session_status_upcoming {
  background: rgba(68, 107, 230, 0.2);
  color: #3959bf;
}
.session_status_ongoing {
  background: rgba(91, 173, 179, 0.2);
  color: #3d7478;
}
.session_status_completed {
  background: #e5e5e5;
  color: #828282;
}
.session_status_cancelled {
  background: rgba(160, 134, 236, 0.2);
  color: #5743ac;
}

/* styles for react-slick */
.slick-slider {
  width: 100%;
  padding-right: 1rem;
}

.slick-slider .slick-list {
  padding: 2rem;
  padding-left: 1.1rem;
}

/* custom styles for Accordion in Collapsible-Row-Table */
.custom-accordion-in-table .chakra-collapse {
  overflow: unset;
  min-width: max-content;
}

/* custom styles for stripe-element inputs */
.StripeElement {
  border-bottom: 1px solid gray;
  padding: 6px 0;
  margin-top: 3px;
}

.StripeElement + .card-icon-group {
  border-bottom-color: gray;
}

.StripeElement--focus,
.StripeElement--focus + .card-icon-group {
  border-color: #3182ce;
  box-shadow: 0px 1px 0px 0px #3182ce;
}

.StripeElement--invalid,
.StripeElement--invalid + .card-icon-group {
  border-color: red;
  box-shadow: 0px 1px 0px 0px red;
}

.react-datepicker-wrapper input {
  background-color: transparent;
}

.chakra-checkbox__control {
  box-shadow: none !important;
}

/* styling for promo codes status */
.promo_status_active {
  background: rgba(68, 107, 230, 0.2);
  color: #3959bf;
}
.promo_status_pending {
  background: rgba(91, 173, 179, 0.2);
  color: #3d7478;
}
.promo_status_expired {
  background: #e5e5e5;
  color: #828282;
}
